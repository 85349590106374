import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Row,
  Typography,
  Statistic,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IStudentList } from "src/services/student/student.model";
import { setIsLogIn } from "src/state/Login/login.reducer";
import { useAppSelector } from "src/state/app.hooks";
import { AppDispatch } from "src/state/app.model";
import { getStudentAdmissionDetailsById } from "src/state/student/student.action";
import { studentSelector } from "src/state/student/student.reducer";
import { config } from "src/utils";
import { CourseCategoryStatusType } from "src/utils/constants/constant";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { IExamData } from "src/services/exam/exam.model";
const { Title, Paragraph, Text } = Typography;
const { Countdown } = Statistic;
const ExamIndex = () => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const studentState = useAppSelector(studentSelector);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState<IStudentList>();
  const [deadline, setDeadline] = useState<any>(null);
  const [isTimePassed, setIsTimePassed] = useState(false);
  const [showStartButton, setShowStartButton] = useState(false);
  const [examData, setExamData] = useState<IExamData>();
  useEffect(() => {
    if (
      studentState.getById.hasErrors &&
      studentState.getById.message === "You have not access this record!"
    ) {
      dispatch(setIsLogIn(false));
    } else {
      setUserDetails(studentState.getById.data[0]);
    }
  }, [studentState.getById]);
  useEffect(() => {
    dispatch(getStudentAdmissionDetailsById(Number(id)));
  }, []);
  const examRules = [
    "Rule 1: No cheating.",
    "Rule 2: No electronic devices allowed.",
    "Rule 3: The exam will automatically close if not completed within the given time.",
    "Rule 4: No communication with other students.",
    "Rule 5: The exam will automatically close if you reload the page.",
  ];
  const examDashboard = () => {
    navigate(`/exam-portal/${id}/dashboard`,{
      state: { data: examData },
    });
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const dataString = queryParams.get("data");
    if (dataString) {
      try {
        const data = JSON.parse(dataString);
        setExamData(data);
      } catch (error) {
        console.error("Failed to parse data:", error);
      }
    }
  }, []);
  useEffect(() => {
    if (window.performance) {
      const navigationEntries = performance.getEntriesByType("navigation") as PerformanceNavigationTiming[];
      if (navigationEntries.length > 0) {
        const navigationType = navigationEntries[0].type;
        if (navigationType === "reload") {
          window.close();
        }
      }
    }
  }, []);
  useEffect(() => {
    if (examData === undefined || isTimePassed===true) return;
    const examDate = moment(examData.created_date);

    const examStartTime = moment(
      `${examDate.format("YYYY-MM-DD")} ${examData.start_time}`,
      "YYYY-MM-DD HH:mm"
    ); 
    const endVisibleTime = moment(examStartTime).add(15, 'minutes');
    const checkTime = () => {
      const currentTime = moment();
      if (currentTime.isBefore(examStartTime)) {
        setDeadline(examStartTime);
      } else if (currentTime.isSame(examStartTime, "minutes") || currentTime.isSameOrBefore(endVisibleTime, "minutes")) {
        setShowStartButton(true);
      } else if (currentTime.isAfter(endVisibleTime)) {
        setIsTimePassed(true);
      } 
    };

    checkTime();
    const interval = setInterval(checkTime, 1000); // Check every second

    return () => clearInterval(interval);
  }, [examData,isTimePassed]);
  
  return (
    <>
      <div className="exam-user-info-card">
        <Card className="exam-card">
          <Row justify="center">
            <Col>
              {userDetails?.admission_documents?.[0]?.photos ? (
                <Avatar
                  size={100}
                  src={
                    userDetails?.admission_documents?.[0]?.photos
                      ? config.bucket_name +
                        "/" +
                        userDetails?.admission_documents?.[0]?.photos
                      : ""
                  }
                />
              ) : (
                <Avatar size={100} icon={<UserOutlined />} />
              )}
            </Col>
          </Row>
          <Row justify="center" className="exam-card-details">
            <Col span={24}>
              <Title level={4} className="card-detail-title">
                {userDetails?.first_name + " " + userDetails?.last_name}
              </Title>
            </Col>
          </Row>
          <Row justify="center" gutter={[16, 16]}>
            <Col span={11}>
              <strong>GR ID:</strong> {userDetails?.gr_id}
            </Col>
            <Col span={13}>
              <strong>Course Name:</strong>{" "}
              {userDetails?.course_category === CourseCategoryStatusType.SINGLE
                ? userDetails?.admission_subcourses[0]?.subcourse?.name
                : userDetails?.package?.name}
            </Col>

            <Col span={11}>
              <strong>Enrollment No:</strong> {userDetails?.enrollment_number}
            </Col>
            <Col span={13}>
              <strong>Email:</strong> {userDetails?.email}
            </Col>

            <Col span={11}>
              <strong>Mobile No:</strong> {userDetails?.mobile_no}
            </Col>
            <Col span={13}>
              <strong>Batch :</strong>{" "}
              {userDetails?.admission_subcourses[0]?.batch?.name}
            </Col>
          </Row>
          <Divider />
          <Row justify="start">
            <Col span={24}>
              <Text strong className="exam-rules">
                Exam Rules:
              </Text>
              {examRules.map((rule, index) => (
                <Paragraph key={index} className="rules-line">
                  - {rule}
                </Paragraph>
              ))}
            </Col>
          </Row>
          <Divider />
          <Row justify="center">
            <Col>
              {isTimePassed ? (
                <div>Exam Time Over</div>
              ) : showStartButton ? (
                <Button type="primary" onClick={examDashboard}>Start Exam</Button>
              ) : (
                <Countdown
                  title="Exam Starts In"
                  value={deadline}
                />
              )}
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};

export default ExamIndex;
