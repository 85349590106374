import { createAsyncThunk } from "@reduxjs/toolkit";
import { IExamPaperStudentAnswer, IGetExamPaperParam, IGetExamParam, ISubmitExamPaperStudentAnswer } from "src/services/exam/exam.model";
import examService from "src/services/exam/exam.service";

export const getExamPaperDetails = createAsyncThunk(
  "student/getExamPaperDetails",
  async (data:IGetExamParam) => {
    return examService.getExamPaperDetails(data.admissionId,data.batch_id);
  }
);

export const getExamPaperDetailsByExamPaperId = createAsyncThunk(
  "student/getExamPaperDetailsByExamPaperId",
  async (data:IGetExamPaperParam) => {
    return examService.getExamPaperDetailsByExamPaperId(data.exam_paper_id,data.admission_id);
  }
);

export const AddExamPaperStudentAnswer = createAsyncThunk(
  "student/AddExamPaperStudentAnswer",
  async (data:IExamPaperStudentAnswer) => {
    return examService.AddExamPaperStudentAnswer(data);
  }
);

export const submitExamPaperStudentAnswer = createAsyncThunk(
  "student/AddExamPaperStudentAnswer",
  async (data:ISubmitExamPaperStudentAnswer) => {
    return examService.submitExamPaperStudentAnswer(data);
  }
);